import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { ClientFactory } from '@/client/ClientFactory.ts';
import { useQuery } from '@tanstack/react-query';
import { exponentialBackoff, maxRetry } from '@/utils/ReactQuery.ts';
import { User } from '@/model/User';

export const DefaultIdIndex = 0;

export interface ServiceIds {
    serviceId: string;
    applicationIds: string[];
}

interface AppIdsContextType {
    serviceIds: ServiceIds;
    setServiceIds: (serviceStatus: ServiceIds) => void;
    setIsLoading: (isLoading: boolean) => void;
    user: User;
    isLoading: boolean;
    isRegister: boolean;
    logout: () => void;
    refetch: () => void;
    setUser: Dispatch<SetStateAction<User>>;
}

const initAppState = {
    serviceIds: {
        applicationIds: [],
        serviceId: '',
    },
    setServiceIds: () => {},
    setIsLoading: () => {},
    logout: () => {},
    refetch: () => {},
    setUser: () => {},
    isLoading: true,
    user: {
        email: '',
        id: '',
        givenName: '',
    },
    isRegister: true,
};

const AppContext = createContext<AppIdsContextType>(initAppState);

export function AppContextProvider({ children }: { children: ReactNode }) {
    const [serviceIds, setServiceIds] = useState<ServiceIds>(initAppState.serviceIds);
    const [user, setUser] = useState<User>(initAppState.user);
    const facadeServiceClient = ClientFactory.createGaramServiceClient();
    const userInfoClient = ClientFactory.createUserInfoClient();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isRegister, setIsRegister] = useState<boolean>(true);

    async function initiateServiceStatus(): Promise<boolean> {
        if (!(await userInfoClient.isUserLoggedIn())) {
            return false;
        }

        try {
            const { email, id: userId, givenName, profileImageUrl } = await userInfoClient.getCurrentUser();
            const { serviceId } = await facadeServiceClient.getService(userId);
            setUser({ email: email ?? '', id: userId, givenName: givenName, profileImageUrl: profileImageUrl });
            setServiceIds((prev) => {
                return {
                    ...prev,
                    serviceId,
                };
            });

            if (serviceId === '') {
                setIsRegister(false);
                setIsLoading(false);
                return false;
            }
            setIsRegister(true);
            const { id: applicationId } = await facadeServiceClient.getApplication(serviceId);
            setServiceIds((prev) => {
                return {
                    ...prev,
                    applicationIds: [applicationId],
                };
            });
        } catch (error) {
            console.error(error);
            throw new Error('Failed to get service status');
        }
        setIsLoading(false);
        return true;
    }

    useEffect(() => {
        if (!isLoading && serviceIds.serviceId === '') {
            setIsRegister(false);
            setIsLoading(false);
        }
    }, [isLoading, serviceIds.serviceId]);

    const { refetch } = useQuery({
        queryKey: ['initiateServiceStatus', serviceIds.serviceId],
        queryFn: initiateServiceStatus,
        retryDelay: exponentialBackoff,
        retry: maxRetry,
    });

    useEffect(() => {
        refetch();
    }, []);

    const logout = () => {
        setServiceIds(initAppState.serviceIds);
        setUser(initAppState.user);
    };

    return <AppContext.Provider value={{ serviceIds, setServiceIds, isLoading, setIsLoading, user, isRegister, logout, refetch, setUser }}>{children}</AppContext.Provider>;
}

export function useAppContext() {
    return useContext(AppContext);
}
