import { Card } from 'react-bootstrap';
import './ConversationAdditiionalInfo.css';
import { calculateTimezoneDifference } from '@/common/helpers/timezoneHelpder';
import { useAppContext } from '@/context/App.context';
import { useQuery } from '@tanstack/react-query';
import { ClientFactory } from '@/client/ClientFactory';

export function AdditionalInfo({ customerId }: { customerId: string }): JSX.Element {
    const { serviceIds } = useAppContext();
    // const targetTimezone = 'America/New_York'; // Timezone example

    const { data: customerInfo } = useQuery({
        queryKey: ['customer', customerId],
        queryFn: async () => ClientFactory.createGaramServiceClient().getCustomerInfo(serviceIds.serviceId, customerId),
    });

    return (
        <Card style={{ height: '100%', padding: '5px 10px', backgroundColor: '#F9F9F9' }}>
            <Card.Body style={{ display: 'flex', gap: 8, flexDirection: 'column', padding: '0px 0px' }}>
                <div className="sectionBox">
                    <p className="sectionTitle">
                        <i className="ri-customer-service-2-fill font-16"></i>
                        {` Customer Information`}
                    </p>

                    <p className="sectionContent">
                        <i className="ri-map-pin-5-fill font-16"></i>
                        {` CountryCode ${customerInfo?.geoLocation.countryCode}`}
                    </p>
                    <p className="sectionContent">
                        <i className="ri-map-pin-5-fill font-16"></i>
                        {` Region ${customerInfo?.geoLocation.region}`}
                    </p>
                    <p className="sectionContent">
                        <i className="ri-building-4-fill font-16"></i>
                        {` City ${customerInfo?.geoLocation.city}`}
                    </p>
                    <p className="sectionContent">
                        <i className="ri-map-pin-time-fill font-16"></i>
                        {` Time difference ${calculateTimezoneDifference(customerInfo?.geoLocation.timeZone ?? '')} hours`}
                    </p>

                    <p className="sectionContent">
                        <i className=" ri-chrome-fill font-16"></i>
                        {` Visit History`}
                        <a className="sectionContent" href="www.google.co.kr">
                            /product/1
                        </a>
                    </p>
                </div>

                <div className="sectionBox">
                    <p className="sectionTitle">
                        <i className="ri-price-tag-3-fill font-16"></i>
                        {` Product Information`}
                    </p>

                    <img
                        className="product-thumbnail"
                        src="https://store.storeimages.cdn-apple.com/4668/as-images.apple.com/is/MXCK3AB?wid=1144&hei=1144&fmt=jpeg&qlt=95&.v=1729185958442"
                    ></img>
                    <p className="sectionContent">Name Keyboard</p>
                    <p className="sectionContent">
                        Link <a href="/gg">Go Product</a>
                    </p>
                </div>
            </Card.Body>
        </Card>
    );
}
