import { FacadeServiceClient } from './api/FacadeServiceClient.ts';
import { ApplicationConfig } from '../config/applicationConfig.ts';
import { GaramFacadeServiceClient } from '@/client/api/GaramFacadeServiceClient.ts';
import { InboxClient } from '@/client/chat/InboxClient.ts';
import { StreamChatInboxClient } from '@/client/chat/StreamChatInboxClient.ts';
import { StreamChat } from 'stream-chat';
import { UserInfoClient } from './user/UserInfoClient.ts';
import { NotificationClient } from '@/client/notification/NotificationClient.ts';
import { StreamNotificationClient } from '@/client/notification/StreamNotificationClient.ts';

export class ClientFactory {
    private static inboxClient: InboxClient;
    private static notificationClient: NotificationClient;

    public static createGaramServiceClient(): FacadeServiceClient {
        return new GaramFacadeServiceClient(ApplicationConfig.apiURL, ClientFactory.createUserInfoClient());
    }

    public static createUserInfoClient(): UserInfoClient {
        return new UserInfoClient(ApplicationConfig.apiURL);
    }

    public static createInboxClient(): InboxClient {
        if (!ClientFactory.inboxClient) {
            ClientFactory.inboxClient = new StreamChatInboxClient(
                ClientFactory.createUserInfoClient(),
                StreamChat.getInstance(ApplicationConfig.streamChatAPIKey),
                ApplicationConfig.apiURL,
            );
        }

        return this.inboxClient;
    }

    public static createNotificationClient(): NotificationClient {
        if (!ClientFactory.notificationClient) {
            ClientFactory.notificationClient = new StreamNotificationClient(
                ClientFactory.createUserInfoClient(),
                StreamChat.getInstance(ApplicationConfig.streamChatAPIKey),
                ApplicationConfig.apiURL,
            );
        }

        return this.notificationClient;
    }
}
