import { useState } from 'react';
import { Card } from 'react-bootstrap';
import './ConversationFilter.css';

interface Props {
    items: { title: string; content: string[] }[];
    onClickItem: (item: string) => void;
}

const AccordionList = ({ items, onClickItem }: Props) => {
    const [openIndexes, setOpenIndexes] = useState<number[]>([0]);

    const toggleItem = (index: number) => {
        setOpenIndexes((prevOpenIndexes) => (prevOpenIndexes.includes(index) ? prevOpenIndexes.filter((i) => i !== index) : [...prevOpenIndexes, index]));
    };

    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    return (
        <Card style={{ height: '100%', backgroundColor: '#F9F9F9' }}>
            <Card.Body style={{ padding: '10px 0px' }}>
                <ul className="list">
                    {items.map((item, index) => (
                        <li key={index}>
                            <div onClick={() => toggleItem(index)} className="header">
                                <span className="accordion-item-text">{item.title}</span>
                                <span>{openIndexes.includes(index) ? ' ▼' : ' ▲'}</span>
                            </div>
                            {openIndexes.includes(index) && (
                                <ul className="accordion-item">
                                    {item.content.map((contentItem, contentIndex) => (
                                        <li
                                            key={contentIndex}
                                            onMouseDown={() => {
                                                console.log(contentIndex);
                                                setSelectedIndex(contentIndex);
                                            }}
                                            className={`accordion-item-text ${contentIndex === selectedIndex ? 'item-text-active' : ''}`}
                                            onClick={() => onClickItem(contentItem)}
                                        >
                                            {contentItem}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </Card.Body>
        </Card>
    );
};

export default AccordionList;
