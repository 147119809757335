import { lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

const ServiceConfiguration = lazy(() => import('./serviceconfiguration'));
const Installation = lazy(() => import('./installation'));

export default function Settings() {
    return (
        <Routes>
            <Route path="/*" element={<Outlet />}>
                <Route path="serviceConfiguration/*" element={<ServiceConfiguration />} />
                <Route path="installation/*" element={<Installation />} />
            </Route>
        </Routes>
    );
}
