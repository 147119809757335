import { UserInfoClient } from '@/client/user/UserInfoClient.ts';
import { MessageResponse, StreamChat } from 'stream-chat';
import { SenderType } from '@/model/SenderType.ts';
import { NotificationClient } from './NotificationClient';
import { Message } from '@/model/Message';

export class StreamNotificationClient implements NotificationClient {
    constructor(
        private readonly userInfoClient: UserInfoClient,
        private readonly chatClient: StreamChat,
        private readonly apiURL: string,
    ) {}

    public async connect(): Promise<void> {
        if (this.chatClient.user) {
            return;
        }

        const user = (await this.userInfoClient.getCurrentUser())!;

        const userToken = await this.getUserToken(user.id);

        await this.chatClient.connectUser(
            {
                id: user.id,
                name: user.givenName,
                role: 'admin',
            },
            userToken,
        );
    }

    public async listenNotification(conversationId: string, callback: (message: Message) => void): Promise<void> {
        await this.connect();
        const channel = this.chatClient.channel('messaging', conversationId);

        const state = await channel.watch();
        if (!Object.hasOwn(state.members, this.chatClient.user!.id)) {
            await channel.addMembers([this.chatClient.user!.id]);
        }

        channel.on('message.new', (event) => {
            callback(this.convertToMessage(event.message!));
        });
    }

    private convertToMessage(streamMessage: MessageResponse): Message {
        return {
            id: streamMessage.id,
            senderType: streamMessage.user!.role!.toLowerCase() === 'user' ? SenderType.Customer : SenderType.Machine,
            messageBody: streamMessage.text!,
        };
    }

    private async getUserToken(userId: string): Promise<string> {
        return await (
            await fetch(`${this.apiURL}/chat/token?userId=${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        ).text();
    }
}
