import { Card } from 'react-bootstrap';

interface NumberStatusCardProps {
    header: string;
    numberValue?: number;
}

export function NumberStatusCard({ header, numberValue }: NumberStatusCardProps) {
    return (
        <Card>
            <Card.Header>
                <h3>{header}</h3>
            </Card.Header>
            <Card.Body>
                <h2>{numberValue}</h2>
            </Card.Body>
        </Card>
    );
}
