import { ChatFormFields } from '@/pages/inbox/hooks/useConversationArea.ts';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { debounce } from 'lodash';
import { useAutoComplete } from '@/pages/inbox/hooks/useAutoComplete.ts';
import { Message } from '@/model/Message.ts';

const DEBOUNCE_PERIOD_MS = 500;

type AutoCompleteTextInputProps = {
    type: 'text' | 'email' | 'number';
    id: string;
    name: string;
    className: string;
    placeholder: string;
    helpText: string;
    disabled: boolean;
    sendChatMessage: (value: ChatFormFields) => Promise<void>;
    messages: Message[];
};

export default function AutoCompleteTextInput({ name, id, className, placeholder, helpText, type, messages, ...props }: AutoCompleteTextInputProps) {
    const { handleKey, clearSuggestions, currentSuggestion } = useAutoComplete(messages);
    const { control } = useFormContext();
    const ONE_LINE_HEIGHT = 35;
    const [height, setHeight] = useState(ONE_LINE_HEIGHT);

    const updateHeight = () => {
        const textarea = document.getElementById('autocomplete-textarea');
        if (textarea) {
            textarea.style.height = 'auto';
            const scrollheight = textarea.scrollHeight;
            textarea.style.height = scrollheight + 'px';
            setHeight(scrollheight);
        }
    };

    const updateHeightBasedOnInput = () => {
        const textarea = document.getElementById('chat-textarea');
        if (textarea) {
            textarea.style.height = 'auto';
            const scrollheight = textarea.scrollHeight;
            textarea.style.height = scrollheight + 'px';
            setHeight(scrollheight);
        }
    };

    useEffect(() => {
        if (currentSuggestion) {
            updateHeight();
        } else {
            updateHeightBasedOnInput();
        }
    }, [currentSuggestion, updateHeight]);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => (
                <Form.Group>
                    <div style={{ display: 'flex', height: '100%', position: 'relative' }}>
                        <Form.Control
                            style={{ height: height }}
                            as="textarea"
                            id={id}
                            type={type}
                            {...props}
                            {...field}
                            value={field.value ?? ''}
                            onChange={(e) => {
                                field.onChange(e.target.value);
                                if (e.target.value === '') {
                                    clearSuggestions();
                                    updateHeight();
                                }
                            }}
                            onKeyUp={(e) => {
                                // @ts-ignore
                                debounce(handleKey, DEBOUNCE_PERIOD_MS)(e, field.value);
                            }}
                            onKeyDown={async (e) => {
                                if (e.key === 'Tab') {
                                    e.preventDefault();
                                    if (currentSuggestion) {
                                        field.onChange(field.value + currentSuggestion);
                                        clearSuggestions();
                                    }
                                } else if (e.key === 'Enter') {
                                    if (e.shiftKey) {
                                        e.preventDefault();
                                        field.onChange(field.value + '\n');
                                    } else {
                                        e.preventDefault();
                                        await props.sendChatMessage({
                                            newMessage: field.value,
                                        });
                                        field.onChange('');
                                    }
                                }
                            }}
                            disabled={props.disabled}
                            placeholder={placeholder}
                            className={className}
                            isInvalid={Boolean(fieldState.error?.message)}
                            autoComplete="off"
                            rows={1}
                        />
                        <textarea
                            rows={1}
                            style={{ height: height }}
                            id="autocomplete-textarea"
                            className="autocomplete-text-input"
                            value={currentSuggestion ? field.value + currentSuggestion : ''}
                            autoComplete="off"
                            disabled={props.disabled}
                            readOnly={true}
                        />
                    </div>
                    {helpText && (
                        <Form.Text id={`${name}-help`} muted>
                            {helpText}
                        </Form.Text>
                    )}
                </Form.Group>
            )}
        />
    );
}
