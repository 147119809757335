import AppRoutes from '@/routes';
import { NotificationProvider, ThemeProvider } from '@/common/context';
import './assets/scss/Saas.scss';
import { Loader } from './components';
import { withUserLogin } from '@/components/Auth/authenticatorHOC';
import { useAppContext } from './context/App.context';
import { useCallback } from 'react';

const App = () => {
    const { isLoading, user, refetch } = useAppContext();

    const render = useCallback(() => {
        if (isLoading) {
            return;
        }
        if (user.id === '') {
            refetch();
            return;
        }

        return <AppRoutes />;
    }, [isLoading, refetch, user.id]);

    return (
        <ThemeProvider>
            <NotificationProvider>
                {isLoading && <Loader />}
                {render()}
            </NotificationProvider>
        </ThemeProvider>
    );
};

export default withUserLogin(App);
