export enum MetricUnit {
    Count = 'Count',
}

export enum MetricNamespace {
    Chat = 'GaramFacade.Chat',
}

export enum MetricName {
    CreatedConversations = 'CreatedConversations',
    ResolvedConversations = 'ResolvedConversations',
}
