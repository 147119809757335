import { Message } from '@/model/Message.ts';
import { ChatMessage, MessageOwnerType } from '@/model/AutoComplete.ts';
import { useAppContext } from '@/context/App.context.tsx';
import { useState } from 'react';
import { ClientFactory } from '@/client/ClientFactory.ts';
import { SenderType } from '@/model/SenderType.ts';

const CHAT_HISTORY_MIN_LENGTH_THRESHOLD = 4;
const CHAT_HISTORY_MAX_LENGTH_THRESHOLD = 100;
const PARTIAL_MESSAGE_LENGTH_THRESHOLD = 15;
const TYPE_MAPPING = {
    [SenderType.Customer]: MessageOwnerType.Customer,
    [SenderType.Machine]: MessageOwnerType.Application,
    [SenderType.HumanAgent]: MessageOwnerType.Application,
};

export function useAutoComplete(messages: Message[]) {
    const { serviceIds } = useAppContext();
    const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState<number>(0);
    const [currentSuggestion, setCurrentSuggestion] = useState<string>('');
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const serviceClient = ClientFactory.createGaramServiceClient();

    const convertMessageToChatMessage = (message: Message): ChatMessage => {
        return {
            id: message.id,
            type: TYPE_MAPPING[message.senderType],
            message: message.messageBody,
        };
    };

    const isValidKey = (key: string): boolean => {
        return key === 'Backspace' || (key.length === 1 && key.match(/[a-zA-Z0-9]/) !== null);
    };

    const getSuggestionsFromService = async (event: React.KeyboardEvent<HTMLInputElement>, partialInput: string) => {
        const currentText = (document.getElementById('chat-textarea') as HTMLInputElement)?.value;
        if (currentText === '') {
            // Sometimes the event is triggered before the value is updated.
            return;
        }
        if (
            messages.length > CHAT_HISTORY_MIN_LENGTH_THRESHOLD &&
            partialInput.length < CHAT_HISTORY_MAX_LENGTH_THRESHOLD &&
            partialInput.length > PARTIAL_MESSAGE_LENGTH_THRESHOLD
        ) {
            const applicationId = serviceIds.applicationIds[0];
            const request = {
                partialMessage: partialInput,
                chatHistory: messages.map((message) => convertMessageToChatMessage(message)),
                numChoices: 3,
            };
            serviceClient.getAutocompleteSuggestions(applicationId, request).then((response) => {
                // set state
                let suggestions = response.suggestions;
                suggestions = suggestions.map((s) => s.replace('"', ''));
                setSuggestions(suggestions);
                setCurrentSuggestion(suggestions[currentSuggestionIndex]);
            });
        }
    };

    const handleKey = async (event: React.KeyboardEvent<HTMLInputElement>, partialInput: string) => {
        if (event.key === 'ArrowDown') {
            setCurrentSuggestionIndex((currentSuggestionIndex + 1) % suggestions.length);
            setCurrentSuggestion(suggestions[currentSuggestionIndex]);
            console.log('New suggestion: ', suggestions[currentSuggestionIndex]);
        } else if (event.key === 'ArrowUp') {
            if (currentSuggestionIndex === 0) {
                return;
            }
            setCurrentSuggestionIndex((currentSuggestionIndex - 1 + suggestions.length) % suggestions.length);
            setCurrentSuggestion(suggestions[currentSuggestionIndex]);
            console.log('New suggestion: ', suggestions[currentSuggestionIndex]);
        } else if (isValidKey(event.key)) {
            getSuggestionsFromService(event, partialInput);
        }
    };

    const clearSuggestions = () => {
        setSuggestions([]);
        setCurrentSuggestion('');
    };

    return {
        handleKey,
        clearSuggestions,
        currentSuggestion,
    };
}
