// export { PricingPage as default } from './PricingPage';

import { lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

const UserProfile = lazy(() => import('./profile/UserProfile'));

export default function AccountSetting() {
    return (
        <Routes>
            <Route path="/*" element={<Outlet />}>
                <Route path="profile" element={<UserProfile />} />
            </Route>
        </Routes>
    );
}
