import { CollectionSyncJob } from '@/model/CollectionSyncJob.ts';
import { InitServiceResponse } from '@/model/InitService.ts';
import { FacadeServiceClient } from './FacadeServiceClient.ts';
import { Collection } from '@/model/Collection.ts';
import { Application } from '@/model/Application.ts';
import { PlainTextResponse } from '@/model/Context.ts';
import { AddDataSourceRequest, AddDataSourceResponse, GetDataSourceResponse } from '@/model/DataSource.ts';
import { getCollectionsMapper } from '@/client/mappers/collectionMapper.ts';
import { Initiate } from '@/model/Initiate.ts';
import { CreateKnowledgeBaseRequest, CreateKnowledgeBaseResponse, UpdateKnowledgeBaseRequest, UpdateKnowledgeBaseResponse } from '@/model/KnowledgeBase.ts';
import { getKnowledgeBaseMapper } from '@/client/mappers/knowledgeBaseMapper.ts';
import { JobType } from '@/model/JobType.ts';
import { KnowledgeBase } from '@/pages/chatbot/DataSource/KnowledgeBase/KnowledgeBasePage.tsx';
import { GetServiceResponse, Service } from '@/model/Service.ts';
import { getServiceMapper } from '../mappers/serviceMapper.ts';
import { ServiceDomainMapping } from '@/model/ServiceDomainMapping.ts';
import { ValidateServiceInitiateResponse } from '@/model/initiate/ValidateServiceInitiateResponse.ts';
import { GetMetricRequest, GetMetricResponse } from '@/model/Metric.ts';
import qs from 'qs';
import { UserInfoClient } from '@/client/user/UserInfoClient.ts';
import { GetAutocompleteSuggestionsRequest, GetAutocompleteSuggestionsReseponse } from '@/model/AutoComplete.ts';
import { ServiceToRegister } from '@/model/ServiceToRegister.ts';
import { URLParamReader } from '@/client/browser/URLParamReader.ts';
import { HttpStatusCode } from 'axios';
import { CustomerEvents } from '@/model/Customer.ts';

export class GaramFacadeServiceClient implements FacadeServiceClient {
    constructor(
        private readonly apiURL: string,
        private readonly userInfoClient: UserInfoClient,
    ) { }

    public async getPlainText(applicationId: string): Promise<PlainTextResponse> {
        const accessToken = await this.getOrThrowToken();
        try {
            return await (
                await fetch(`${this.apiURL}/${applicationId}/context/agentScript`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
            ).json();
        } catch (error) {
            console.error(error);
            throw new Error();
        }
    }

    public async getCollectionSyncHistories(serviceId: string, applicationId: string, type: JobType): Promise<CollectionSyncJob[]> {
        const refreshJobHistories: any[] = (
            await (
                await fetch(`${this.apiURL}/${serviceId}/${applicationId}/refreshJobsHistory`, {
                    method: 'GET',
                })
            ).json()
        )['refreshJobs'];

        return refreshJobHistories
            .filter((refreshJobHistory) => refreshJobHistory.type === type.toString())
            .map((refreshJobHistory) => ({
                id: refreshJobHistory.jobId,
                collectionId: refreshJobHistory.collectionId,
                startTime: refreshJobHistory.startTime,
                endTime: refreshJobHistory.endTime,
                status: refreshJobHistory.status,
                documentStatus: {
                    scanned: '0',
                },
            }));
    }
    public async updateApplication(serviceId: string, application: Application): Promise<boolean> {
        const accessToken = await this.getOrThrowToken();
        await (
            await fetch(`${this.apiURL}/applications`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                method: 'PUT',
                body: JSON.stringify({
                    id: application.id,
                    serviceId: serviceId,
                    name: application.name,
                    description: application.description,
                    type: application.type,
                    greetingMessage: application.greetingMessage,
                    operationTimeSlots: application.operationTimeSlots,
                }),
            })
        ).json();

        return true;
    }

    public async createPlainText(applicationId: string, context: string): Promise<void> {
        const accessToken = await this.getOrThrowToken();
        try {
            await (
                await fetch(`${this.apiURL}/${applicationId}/context`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({
                        context,
                    }),
                })
            ).json();
        } catch (error) {
            console.error(error);
            throw new Error();
        }
    }

    public async getServiceId(userId: string): Promise<string> {
        const service = (await (await fetch(`${this.apiURL}/services/${userId}`, { method: 'GET' })).json())[0];
        return service ? service.id : '';
    }

    public async updateService(service: Service): Promise<void> {
        const accessToken = await this.getOrThrowToken();
        await fetch(`${this.apiURL}/services`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(service),
        });
    }

    public async getService(userId: string): Promise<Service> {
        const service: GetServiceResponse = (await (await fetch(`${this.apiURL}/services/${userId}`, { method: 'GET' })).json())[0];

        return getServiceMapper(service);
    }

    public async getApplication(serviceId: string): Promise<Application> {
        const value = await (
            await fetch(`${this.apiURL}/applications?serviceId=${serviceId}`, {
                method: 'GET',
            })
        ).json();

        return {
            ...value[0],
            operationTimeSlots: this.convertTimeslot(value[0].operationTimeSlots),
        };
    }

    private convertTimeslot(opertaionTimeslotsStr: { startAt: string; endAt: string }[]) {
        return opertaionTimeslotsStr.map((time) => {
            return {
                startAt: new Date(time.startAt),
                endAt: new Date(time.endAt),
            };
        });
    }

    public async getCollections(applicationId: string): Promise<Collection[]> {
        const res = await (
            await fetch(`${this.apiURL}/${applicationId}/collections`, {
                method: 'GET',
            })
        ).json();

        return getCollectionsMapper(res);
    }

    public async addDataSource(applicationId: string, collectionId: string, dataSource: AddDataSourceRequest): Promise<AddDataSourceResponse> {
        const accessToken = await this.getOrThrowToken();
        return await (
            await fetch(`${this.apiURL}/${applicationId}/${collectionId}/dataSource`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(dataSource),
            })
        ).json();
    }

    public async getDataSources(applicationId: string, collectionId: string): Promise<GetDataSourceResponse> {
        return await (
            await fetch(`${this.apiURL}/${applicationId}/${collectionId}/dataSource`, {
                method: 'GET',
            })
        ).json();
    }

    public async deleteDataSources(applicationId: string, collectionId: string, dataSourceId: string): Promise<boolean> {
        const accessToken = await this.getOrThrowToken();
        return await (
            await fetch(`${this.apiURL}/${applicationId}/${collectionId}/dataSource/${dataSourceId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                method: 'DELETE',
            })
        ).json();
    }

    public async validateInitiateRequest(initiate: Initiate): Promise<ValidateServiceInitiateResponse> {
        const accessToken = await this.getOrThrowToken();
        return await (
            await fetch(`${this.apiURL}/initiate/validate`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                method: 'POST',
                body: JSON.stringify(initiate),
            })
        ).json();
    }

    public async initiateService(initiate: Initiate): Promise<InitServiceResponse> {
        const accessToken = await this.getOrThrowToken();
        return await (
            await fetch(`${this.apiURL}/initiate`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                method: 'POST',
                body: JSON.stringify(initiate),
            })
        ).json();
    }

    public async createKnowledgeBase(applicationId: string, body: CreateKnowledgeBaseRequest): Promise<CreateKnowledgeBaseResponse> {
        const accessToken = await this.getOrThrowToken();
        return await (
            await fetch(`${this.apiURL}/${applicationId}/knowledgeBases`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                method: 'POST',
                body: JSON.stringify(body),
            })
        ).json();
    }

    public async getKnowledgeBase(applicationId: string): Promise<KnowledgeBase[]> {
        const res = await (
            await fetch(`${this.apiURL}/${applicationId}/knowledgeBases`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'GET',
            })
        ).json();
        return getKnowledgeBaseMapper(res);
    }

    public async updateKnowledgeBase(applicationId: string, body: UpdateKnowledgeBaseRequest): Promise<UpdateKnowledgeBaseResponse> {
        const accessToken = await this.getOrThrowToken();
        return await (
            await fetch(`${this.apiURL}/${applicationId}/knowledgeBases`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                method: 'PUT',
                body: JSON.stringify(body),
            })
        ).json();
    }

    public async deleteKnowledgeBase(applicationId: string, knowledgeBaseId: string): Promise<boolean> {
        const accessToken = await this.getOrThrowToken();
        return await (
            await fetch(`${this.apiURL}/${applicationId}/knowledgeBases/${knowledgeBaseId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                method: 'DELETE',
            })
        ).json();
    }

    public async createCheckoutSession(serviceId: string, plan: string): Promise<{ id: string }> {
        const accessToken = await this.getOrThrowToken();
        return await (
            await fetch(`${this.apiURL}/${serviceId}/payments/session?plan=${plan}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
            })
        ).json();
    }

    public async getServiceDomainMapping(serviceId: string): Promise<ServiceDomainMapping> {
        return (
            await (
                await fetch(`${this.apiURL}/${serviceId}/domain`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'GET',
                })
            ).json()
        )[0];
    }

    public async upsertServiceDomainMapping(serviceDomainMapping: ServiceDomainMapping): Promise<void> {
        const accessToken = await this.getOrThrowToken();
        await (
            await fetch(`${this.apiURL}/${serviceDomainMapping.serviceId}/domain`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                method: 'POST',
                body: JSON.stringify(serviceDomainMapping),
            })
        ).json();
    }

    public async getMetric(params: GetMetricRequest): Promise<GetMetricResponse> {
        return await (
            await fetch(`${this.apiURL}/metrics?${qs.stringify(params)}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'GET',
            })
        ).json();
    }

    public async getAutocompleteSuggestions(applicationId: string, body: GetAutocompleteSuggestionsRequest): Promise<GetAutocompleteSuggestionsReseponse> {
        return await (
            await fetch(`${this.apiURL}/applications/${applicationId}/llm/autocomplete`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(body),
            })
        ).json();
    }

    public async scanHomePage(homePageURL: string): Promise<ServiceToRegister | undefined> {
        const response = await fetch(`${this.apiURL}/category/homepage?homePageUrl=${URLParamReader.appendHttpProtocolIfMissing(homePageURL)}`, {
            method: 'GET',
        });

        if (response.status === HttpStatusCode.BadRequest) {
            throw new Error('Invalid URL');
        }

        return response.json();
    }

    public async validateUrl(domain: string): Promise<boolean> {
        let response;

        try {
            response = await (await fetch(`${this.apiURL}/category/validateUrl?url=${URLParamReader.appendHttpProtocolIfMissing(domain)}`)).json();
        } catch (error) {
            return false;
        }

        return response.isValid;
    }

    private async getOrThrowToken(): Promise<string> {
        const token = await this.userInfoClient.getAccessToken();
        if (!token) {
            throw new Error('No token found');
        }

        return token;
    }

    public async getCustomerInfo(serviceId: string, customerId: string): Promise<CustomerEvents> {
        const res = await (
            await fetch(`${this.apiURL}/customerEvents?serviceId=${serviceId}&customerId=${customerId}`, {
                method: 'GET',
            })
        ).json();

        return res;
    }
}
