import moment from 'moment-timezone';

export function calculateTimezoneDifference(targetTimezone: string): number {
  const localTimezone = moment.tz.guess();
  const localOffset = moment.tz(localTimezone).utcOffset();

  const targetOffset = moment.tz(targetTimezone).utcOffset();

  const differenceInHours = (targetOffset - localOffset) / 60;

  return differenceInHours;
}