import { Notification, MESSAGE_TYPE } from '@/model/Notification';

export const getNotification = (message: string): Notification => {
    return JSON.parse(message);
};

export const getNotificationTitleByType = (type: MESSAGE_TYPE): string => {
    switch (type) {
        case MESSAGE_TYPE.NEW_MESSAGE:
            return 'New Message';
        default:
            return '';
    }
};
