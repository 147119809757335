import { PageBreadcrumb } from '@/components';
import { Row } from 'react-bootstrap';
import React from 'react';
import ConversationArea from './ConversationArea';
import useConversationDetail from './hooks/useConversationDetail';
import { ComponentLoader } from '@/components/ComponentLoader';

const ConversationsDetail = () => {
    const [conversation, updateConversation] = useConversationDetail();

    return (
        <>
            <PageBreadcrumb title="" subNames={[{ name: 'Inbox' }]} />

            <Row style={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto' }}>
                <ComponentLoader isLoading={!conversation} />
                {conversation && <ConversationArea selectedConversation={conversation} updateConversation={updateConversation} />}
            </Row>
        </>
    );
};

export default React.memo(ConversationsDetail);
