import { useNotificationContext } from '@/common/context';
import * as yup from 'yup';
import { ClientFactory } from '@/client/ClientFactory.ts';
import { useMutation } from '@tanstack/react-query';
import { useAppContext } from '@/context/App.context';
import { useState } from 'react';
import { Initiate } from '@/model/Initiate.ts';
import { ValidateResultResponse, ValidateServiceInitiateResponse } from '@/model/initiate/ValidateServiceInitiateResponse.ts';
import { ServiceToRegister } from '@/model/ServiceToRegister.ts';
import { useNavigate } from 'react-router-dom';

export default function useRegister() {
    const serviceClient = ClientFactory.createGaramServiceClient();
    const { showNotification } = useNotificationContext();
    const navigate = useNavigate();
    const [verificationResult, setVerificationResult] = useState<ValidateServiceInitiateResponse>({
        isValid: true,
        validateResults: [],
    });

    const schema = yup.object<ServiceToRegister>().shape({
        siteDomain: yup.string().required('Please enter site domain.'),
        serviceName: yup.string().required('Please enter service name.'),
        agentName: yup.string().required('Please enter application name.'),
        serviceDescription: yup.string().required('Please enter application description.'),
        checkbox: yup.boolean().oneOf([true], 'You must accept the terms and conditions').required('You must accept the terms and conditions'),
    });

    async function initiateService(data: ServiceToRegister) {
        const initiateServiceRequest: Initiate = { ...data, userId: user.id, applicationName: data.agentName, description: data.serviceDescription };
        const response = await serviceClient.validateInitiateRequest(initiateServiceRequest);
        setVerificationResult(response);
        if (!response.isValid) {
            throw Error('Invalid registration');
        }

        return serviceClient.initiateService(initiateServiceRequest);
    }

    function convertToTextInputError(validationResults: ValidateResultResponse[]): { [key: string]: { message: string } } {
        return validationResults.reduce((acc, result) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            acc[result.resultKey] = { message: result.validateMessage };
            return acc;
        }, {});
    }

    const { setServiceIds, user } = useAppContext();
    const { mutate: register, isPending } = useMutation({
        mutationFn: async (data: ServiceToRegister) => initiateService(data),
        onSuccess: (data) => {
            setServiceIds({
                serviceId: data.service.id,
                applicationIds: [data.application.id],
            });

            showNotification({
                title: 'Success',
                message: 'Registration successful. Welcome Garam!',
                type: 'success',
            });

            navigate('/');
        },
        onError: () => {
            showNotification({
                title: 'Error',
                message: 'Registration fail.',
                type: 'error',
            });
        },
    });

    return { isPending, register, schema, errors: convertToTextInputError(verificationResult.validateResults) };
}
