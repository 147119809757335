import { useId } from 'react';

interface Props {
    label?: {
        enable: string;
        disable: string;
    };
    status: boolean;
    onChangeStatus: () => void;
}

export function SwitchButton({ label = { enable: 'On', disable: 'Off' }, status, onChangeStatus }: Props): JSX.Element {
    const id = useId();

    return (
        <>
            <input type="checkbox" id={`${id}-switch`} data-switch="success" checked={status} onChange={onChangeStatus} />
            <label htmlFor={`${id}-switch`} data-on-label={label.enable} data-off-label={label.disable} className="mb-0 d-block"></label>
        </>
    );
}
