import { useAppContext } from '@/context/App.context.tsx';
import { BaseCard } from '../../../home/cards/BaseCard.tsx';
import SimpleLineChart from '@/components/Charts/SimpleLineChart/SimpleLineChart.tsx';
import { useQuery } from '@tanstack/react-query';
import { ClientFactory } from '@/client/ClientFactory.ts';
import { MetricName, MetricNamespace } from '@/client/api/MetricType.ts';
import { useMetrics } from '@/pages/dashboard/component/useMetrics.ts';

interface ConversationByHourProps {
    metricTitle: string;
    metricName: MetricName;
}

export function ConversationByHourCard({ metricTitle, metricName }: ConversationByHourProps): JSX.Element {
    const client = ClientFactory.createGaramServiceClient();
    const { serviceIds } = useAppContext();
    const { getOneWeekBefore, convertToMetricTimeString } = useMetrics();

    const { data, isLoading } = useQuery({
        queryKey: ['dashboard', 'conversationByHour', metricName],
        queryFn: async () => {
            const currentDate = new Date();

            return client.getMetric({
                namespace: MetricNamespace.Chat,
                metricName: metricName,
                serviceId: serviceIds.serviceId,
                startTime: convertToMetricTimeString(getOneWeekBefore(currentDate)),
                endTime: convertToMetricTimeString(currentDate),
            });
        },
        refetchInterval: 60 * 1000,
        refetchIntervalInBackground: true,
    });

    return (
        <BaseCard isLoading={isLoading}>
            {!isLoading && (
                <>
                    <h4 className="header-title mb-4">{metricTitle}</h4>
                    <SimpleLineChart
                        name="Conversations"
                        xAxis={data![0].timestamps.map((timestamp) => new Date(timestamp).toLocaleTimeString('en-US', { month: 'short', day: 'numeric', hour: 'numeric' }))}
                        data={data![0].values}
                    />
                </>
            )}
        </BaseCard>
    );
}
