import { useState, useEffect } from 'react';

function useMessageRotator(messages: string[], intervalTime = 3000) {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => {
                if (prevIndex === messages.length - 1) {
                    return prevIndex;
                } else {
                    return prevIndex + 1;
                }
            });
        }, intervalTime);

        return () => clearInterval(interval);
    }, [index, messages, intervalTime]);

    return messages[index];
}

export default useMessageRotator;
