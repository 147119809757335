import { CheckInput, Form, Loader, TextAreaInput, TextInput } from '@/components';
import { Button } from 'react-bootstrap';
import Wrapper from '@/pages/register/Wrapper.tsx';
import useRegister from '@/pages/register/useRegister.ts';
import { useFormDefaultValue } from '@/pages/register/useFormDefaultValue.ts';
import { useTranslation } from 'react-i18next';
import { ServiceToRegister } from '@/model/ServiceToRegister';
import useMessageRotator from '@/hooks/useLoaderMessageList';

interface SubmitServiceInitiationProps {
    serviceToRegister: ServiceToRegister;
}

export default function SubmitServiceInitiation({ serviceToRegister }: SubmitServiceInitiationProps) {
    const { isPending, register, schema, errors } = useRegister();
    const { isPredefinedSiteDomain } = useFormDefaultValue();
    const { t } = useTranslation();

    const messageList = ['서비스 정보를 확인하고 있습니다.', '서비스 정보를 등록하고 있습니다.', '서비스를 사용할 준비가 거의 다 되었습니다.', '조금 뒤 가람을 사용해보세요.'];
    const currentMessage = useMessageRotator(messageList, 3000);

    if (isPending) {
        return <Loader message={currentMessage} />;
    }

    return (
        <Wrapper>
            <div className="text-center w-75 m-auto">
                <h4 className="text-dark-50 text-center mt-0 fw-bold">{t('Register your services!')}</h4>
                <p className="text-muted mb-4">{t('Let me know if we understand your website correctly.')}</p>
            </div>

            <Form
                onSubmit={(data) => {
                    register(data);
                }}
                schema={schema}
                defaultValues={serviceToRegister}
                preventResetAfterSubmit
            >
                <TextInput
                    label={'Shopping mall domain'}
                    type="text"
                    name="siteDomain"
                    placeholder={'your.service.domain'}
                    containerClass="mb-3"
                    helpText={t('HelpSiteDomain')}
                    disabled={isPredefinedSiteDomain()}
                    errors={errors}
                />
                <TextInput
                    label={'Shopping mall name'}
                    type="text"
                    name="serviceName"
                    placeholder={'Customer Service'}
                    containerClass="mb-3"
                    helpText={t('HelpServiceName')}
                    errors={errors}
                />
                <TextAreaInput
                    label={'Short description for shopping mall'}
                    name="serviceDescription"
                    placeholder={'Shopping mall description'}
                    containerClass="mb-3"
                    helpText={t('HelpApplicationDescription')}
                    errors={errors}
                />
                <TextInput
                    label={'AI agent name'}
                    type="text"
                    name="agentName"
                    placeholder={'Your shopping mall agent name'}
                    containerClass="mb-3"
                    helpText={t('HelpApplicationName')}
                    errors={errors}
                />
                <CheckInput
                    name="checkbox"
                    type="checkbox"
                    containerClass="mb-2"
                    label={
                        <>
                            I accept
                            <a href={'https://privacy.26area.com/'} target="_blank" className="text-muted cursor-pointer" rel="noreferrer">
                                {' '}
                                Terms and Conditions
                            </a>
                        </>
                    }
                />

                <div className="mb-3 text-center">
                    <Button variant="primary" type="submit" disabled={isPending}>
                        {'Register'}
                    </Button>
                </div>
            </Form>
        </Wrapper>
    );
}
