import { Col, Row } from 'react-bootstrap';
import { PageBreadcrumb } from '@/components';

import { MetricName } from '@/client/api/MetricType.ts';
import { useTranslation } from 'react-i18next';
import { ConversationByHourCard } from '@/pages/dashboard/component/ConversationByHour';

const DashboardConversation = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <>
            <PageBreadcrumb title="Conversation Dashboard" subNames={[{ name: 'Dashboard' }, { name: 'Conversation' }]} />

            <Row className="mt-4">
                <Col xl={6}>
                    <ConversationByHourCard metricName={MetricName.CreatedConversations} metricTitle={t('Weekly Created Conversation')} />
                </Col>

                <Col xl={6}>
                    <ConversationByHourCard metricName={MetricName.ResolvedConversations} metricTitle={t('Weekly Resolved Conversation')} />
                </Col>
            </Row>
        </>
    );
};

export { DashboardConversation };
