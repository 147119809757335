import { Message } from '@/model/Message.ts';
import { SenderType } from '@/model/SenderType.ts';

export function useMessage(message: Message) {
    function getMessageSenderName(): string {
        return new Map([
            [SenderType.HumanAgent, 'Agent'],
            [SenderType.Customer, 'Customer'],
            [SenderType.Machine, 'Garam AI'],
        ]).get(message.senderType)!;
    }

    return {
        getMessageSenderName,
    };
}
