import { useCallback, useEffect, useLayoutEffect, useMemo, useReducer } from 'react';
import { ConversationStatus } from '../types';
import { ClientFactory } from '@/client/ClientFactory.ts';
import { useAppContext } from '@/context/App.context';
import { useMutation } from '@tanstack/react-query';
import { ActionType, initialState, reducer } from './useConversationsReducer';
import { useNotificationContext } from '@/common';
import { useTranslation } from 'react-i18next';
import { Conversation } from '@/model/chat/Conversation';
import { useConversationsStatus } from '@/hooks/inbox/useConversationsStatus';
import { isUndefined } from 'lodash';

export default function useConversations() {
    const inboxClient = ClientFactory.createInboxClient();
    const { serviceIds } = useAppContext();
    const { showNotification } = useNotificationContext();
    const { t } = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);

    const fetchOpen = useCallback(async () => {
        const data = await inboxClient.getConversations(serviceIds.serviceId, ConversationStatus.Open, state.conversations.open.nextToken);
        dispatch({
            type: ActionType.SET_OPEN_CONVERSATIONS,
            value: {
                open: {
                    conversations: data?.conversations,
                    nextToken: data?.nextToken,
                },
            },
        });
    }, [inboxClient, serviceIds.serviceId, state.conversations.open.nextToken]);

    useEffect(() => {}, [state.conversations.open.conversations]);

    const fetchClosed = useCallback(async () => {
        const data = await inboxClient.getConversations(serviceIds.serviceId, ConversationStatus.Closed, state.conversations.closed.nextToken);
        dispatch({
            type: ActionType.SET_CLOSED_CONVERSATIONS,
            value: {
                closed: {
                    conversations: data?.conversations,
                    nextToken: data?.nextToken,
                },
            },
        });
    }, [inboxClient, serviceIds.serviceId, state.conversations.closed.nextToken]);

    useLayoutEffect(() => {
        fetchOpen();
        fetchClosed();
    }, []);

    const { refetch: refetchConversationCount } = useConversationsStatus();

    const updateSelectedStatus = useCallback((status: ConversationStatus) => {
        dispatch({ type: ActionType.SET_SELECTED_STATUS, value: status });
    }, []);

    const updateSelectedIndex = useCallback((index: number) => {
        dispatch({ type: ActionType.SET_SELECTED_INDEX, value: index });
    }, []);

    // TODO status 분기 줄일수있을까 ?
    const conversations = useMemo(() => {
        if (state.selected.status === ConversationStatus.Open) {
            return state.conversations.open.conversations;
        } else {
            return state.conversations.closed.conversations;
        }
    }, [state.conversations.closed.conversations, state.conversations.open.conversations, state.selected.status]);

    const selectedIndex = useMemo(() => {
        if (state.selected.status === ConversationStatus.Open) {
            return state.selected.index.open;
        } else {
            return state.selected.index.closed;
        }
    }, [state.selected.index.closed, state.selected.index.open, state.selected.status]);

    const selectedConversation = useMemo(() => {
        if (state.selected.status === ConversationStatus.Open) {
            return state.conversations.open.conversations[state.selected.index.open];
        } else {
            return state.conversations.closed.conversations[state.selected.index.closed];
        }
    }, [state.conversations.closed.conversations, state.conversations.open.conversations, state.selected.index.closed, state.selected.index.open, state.selected.status]);

    const { mutate } = useMutation({
        mutationFn: async (conversation: Conversation) => inboxClient.updateConversation(conversation),
        onSuccess: (data) => {
            refetchConversationCount();
            showNotification({
                title: 'Success',
                message: t('MESSAGE_UPDATE_CONVERSATION_SUCCESS'),
                type: 'success',
            });
            if (selectedConversation.status !== data.status) {
                // TODO BE에서 필드를 다 주지않음
                dispatch({ type: ActionType.UPDATE_CONVERSATION_STATUS, value: { ...selectedConversation, ...data } });
            } else {
                dispatch({ type: ActionType.UPDATE_CONVERSATION, value: { ...selectedConversation, ...data } });
            }
        },
        onError: () => {
            showNotification({
                title: 'Error',
                message: t('MESSAGE_UPDATE_CONVERSATION_FAIL'),
                type: 'error',
            });
        },
    });

    const updateConversation = useCallback(
        (conversation: Conversation) => {
            mutate(conversation);
        },
        [mutate],
    );

    const hasMore = useMemo(() => {
        if (state.selected.status === ConversationStatus.Open) {
            return state.conversations.open.conversations.length > 0 && !isUndefined(state.conversations.open.nextToken);
        } else {
            return state.conversations.closed.conversations.length > 0 && !isUndefined(state.conversations.closed.nextToken);
        }
    }, [state.conversations.closed.conversations.length, state.conversations.closed.nextToken, state.conversations.open, state.selected.status]);

    const refetch = useCallback(() => {
        fetchOpen();
        fetchClosed();
    }, [fetchClosed, fetchOpen]);

    return {
        selectedIndex: conversations.length > selectedIndex ? selectedIndex : selectedIndex - 1,
        selectedStatus: state.selected.status,
        conversations,
        isLoading: false,
        selectedConversation,
        hasMore,

        refetch,
        updateConversation,
        updateSelectedIndex,
        updateSelectedStatus,
    };
}
