import { ComponentLoader } from '@/components/ComponentLoader';
import { ReactNode } from 'react';
import { Card } from 'react-bootstrap';

export function BaseCard({ children, isLoading = false }: { children: ReactNode; isLoading?: boolean }) {
    return (
        <Card>
            <ComponentLoader isLoading={isLoading} />
            <Card.Body>{children}</Card.Body>
        </Card>
    );
}
