export class URLParamReader {
    private static readonly SHOPPING_MALL_DOMAIN_PARAM = 'shop';

    private constructor() {}

    public static getShoppingMallDomainFromParam(): string | undefined {
        const shoppingMallDomain = new URLSearchParams(location.search).get(URLParamReader.SHOPPING_MALL_DOMAIN_PARAM);

        if (shoppingMallDomain) {
            return shoppingMallDomain;
        }

        return undefined;
    }

    public static appendHttpProtocolIfMissing(domain: string): string {
        if (domain.startsWith('http://') || domain.startsWith('https://')) {
            return domain;
        }

        return `https://${domain}`;
    }
}
