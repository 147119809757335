import SubmitServiceInitiation from '@/pages/register/submit';
import { useSetUp } from '@/pages/register/setup/useSetUp.ts';
import SetUpService from '@/pages/register/setup';
import { useState } from 'react';
import { ServiceToRegister } from '@/model/ServiceToRegister';

export default function Register() {
    const [serviceToRegister, setServiceToRegister] = useState<Partial<ServiceToRegister>>({});
    const { isSetUpComplete } = useSetUp(serviceToRegister, setServiceToRegister);

    if (!isSetUpComplete()) {
        return <SetUpService serviceToRegister={serviceToRegister} setServiceToRegister={setServiceToRegister} />;
    }

    return (
        <SubmitServiceInitiation
            serviceToRegister={{
                serviceName: serviceToRegister.serviceName!,
                siteDomain: serviceToRegister.siteDomain!,
                agentName: serviceToRegister.agentName!,
                serviceDescription: serviceToRegister.serviceDescription!,
                greetingMessage: serviceToRegister.greetingMessage!,
                checkbox: false,
            }}
        />
    );
}
