import { Outlet, Route, Routes } from 'react-router-dom';
import { DashboardConversation } from './conversation';

export default function Dashboard() {
    return (
        <Routes>
            <Route path="/*" element={<Outlet />}>
                <Route path="conversation" element={<DashboardConversation />} />
                {/*<Route path="aiAssistance" element={<DashboardAiAssistance />} />*/}
            </Route>
        </Routes>
    );
}
