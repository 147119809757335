export function useMetrics() {
    function getOneWeekBefore(dateToMinus: Date): Date {
        const oneWeekBefore = new Date(dateToMinus);
        oneWeekBefore.setDate(dateToMinus.getDate() - 7);

        return oneWeekBefore;
    }

    function convertToMetricTimeString(timeToConvert: Date): string {
        return timeToConvert.toISOString().replace(/\.\d{3}Z$/, 'Z');
    }

    return { getOneWeekBefore, convertToMetricTimeString };
}
