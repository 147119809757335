import { GetServiceResponse, Service } from '@/model/Service';

export const getServiceMapper = (res: GetServiceResponse): Service => {
    if (!res) {
        return {
            serviceId: '',
            description: '',
            serviceName: '',
            config: {
                enableChatBotAsDefault: true,
            },
        };
    }
    return {
        serviceId: res.id,
        serviceName: res.name,
        description: res.description,
        config: res.config,
    };
};
