import { Conversation } from '@/model/chat/Conversation';
import { ConversationStatus } from '../types';
import { cloneDeep } from 'lodash';

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.UPDATE_CONVERSATION_STATUS: {
            const updateConversations = cloneDeep(state.conversations);
            if (action.value.status === ConversationStatus.Closed) {
                const index = state.conversations.closed.conversations.findIndex((item) => item.lastMessageAt < action.value.lastMessageAt);
                updateConversations.open.conversations.splice(state.selected.index.open, 1);
                updateConversations.closed.conversations.splice(index, 0, action.value);
            } else {
                const index = state.conversations.open.conversations.findIndex((item) => item.lastMessageAt < action.value.lastMessageAt);
                updateConversations.closed.conversations.splice(state.selected.index.closed, 1);
                updateConversations.open.conversations.splice(index, 0, action.value);
            }
            return {
                ...state,
                conversations: updateConversations,
            };
        }
        case ActionType.UPDATE_CONVERSATION: {
            const updateConversations = cloneDeep(state.conversations);
            if (state.selected.status === ConversationStatus.Open) {
                updateConversations.open.conversations[state.selected.index.open] = { ...updateConversations.open.conversations[state.selected.index.open], ...action.value };
            } else {
                updateConversations.closed.conversations[state.selected.index.closed] = {
                    ...updateConversations.closed.conversations[state.selected.index.closed],
                    ...action.value,
                };
            }

            return {
                ...state,
                conversations: updateConversations,
            };
        }
        case ActionType.SET_OPEN_CONVERSATIONS:
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    open: {
                        conversations: [...state.conversations.open.conversations, ...action.value.open.conversations],
                        nextToken: action.value.open.nextToken,
                    },
                },
            };
        case ActionType.SET_CLOSED_CONVERSATIONS:
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    closed: {
                        conversations: [...state.conversations.closed.conversations, ...action.value.closed.conversations],
                        nextToken: action.value.closed.nextToken,
                    },
                },
            };
        case ActionType.SET_SELECTED_STATUS:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    status: action.value,
                },
            };
        case ActionType.SET_SELECTED_INDEX:
            if (state.selected.status === ConversationStatus.Open) {
                return {
                    ...state,
                    selected: {
                        ...state.selected,
                        index: {
                            ...state.selected.index,
                            open: action.value,
                        },
                    },
                };
            } else {
                return {
                    ...state,
                    selected: {
                        ...state.selected,
                        index: {
                            ...state.selected.index,
                            closed: action.value,
                        },
                    },
                };
            }
        default:
            throw new Error('Unhandled action type: ' + action);
    }
};

interface UpdateConversationAction {
    type: ActionType.UPDATE_CONVERSATION;
    value: Conversation;
}

interface UpdateConversationStatusAction {
    type: ActionType.UPDATE_CONVERSATION_STATUS;
    value: Conversation;
}

interface SetClosedConversationsAction {
    type: ActionType.SET_CLOSED_CONVERSATIONS;
    value: {
        closed: { conversations: Conversation[]; nextToken?: string };
    };
}

interface SetOpenConversationsAction {
    type: ActionType.SET_OPEN_CONVERSATIONS;
    value: {
        open: {
            conversations: Conversation[];
            nextToken?: string;
        };
    };
}

interface SetSelectedStatusAction {
    type: ActionType.SET_SELECTED_STATUS;
    value: ConversationStatus;
}

interface SetSelectedIndexAction {
    type: ActionType.SET_SELECTED_INDEX;
    value: number;
}

type Action =
    | UpdateConversationAction
    | SetClosedConversationsAction
    | SetOpenConversationsAction
    | SetSelectedStatusAction
    | SetSelectedIndexAction
    | UpdateConversationStatusAction;

export enum ActionType {
    UPDATE_CONVERSATION,
    SET_CONVERSATIONS,
    SET_SELECTED_STATUS,
    SET_SELECTED_INDEX,
    UPDATE_CONVERSATION_STATUS,
    SET_CLOSED_CONVERSATIONS,
    SET_OPEN_CONVERSATIONS,
}

export interface State {
    conversations: {
        open: {
            conversations: Conversation[];
            nextToken?: string;
        };
        closed: { conversations: Conversation[]; nextToken?: string };
    };
    selected: {
        index: {
            open: number;
            closed: number;
        };
        status: ConversationStatus;
    };
}

export const initialState: State = {
    conversations: {
        open: {
            conversations: [],
        },
        closed: {
            conversations: [],
        },
    },
    selected: {
        index: {
            open: 0,
            closed: 0,
        },
        status: ConversationStatus.Open,
    },
};
