import ReactApexChart from 'react-apexcharts';
import { lineChartOpts } from './options';

interface Props {
    xAxis: string[];
    data: number[];
    name: string;
}

const SimpleLineChart = ({ data, name, xAxis }: Props) => {
    const options = {
        ...lineChartOpts,
        xaxis: {
            categories: xAxis,
        },
        series: [
            {
                name,
                data,
            },
        ],
    };
    return <ReactApexChart className="apex-charts" options={options} height={295} series={options.series} type="line" />;
};

export default SimpleLineChart;
