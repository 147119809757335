import { createContext, ReactNode, useContext, useState } from 'react';

interface ConversationCount {
    open: number;
    closed: number;
}
interface NotificationContextType {
    conversationCount: ConversationCount;
    setUpdateConversationCount: (count: ConversationCount) => void;
}

interface NotificationContextProviderProps {
    children: ReactNode;
}

const NotificationContext = createContext<NotificationContextType>({
    conversationCount: {
        open: 0,
        closed: 0,
    },
    setUpdateConversationCount: () => {},
});

export function NotificationContextProvider({ children }: NotificationContextProviderProps) {
    const [conversationCount, setUpdateConversationCount] = useState<ConversationCount>({
        open: 0,
        closed: 0,
    });

    return (
        <NotificationContext.Provider
            value={{
                conversationCount,
                setUpdateConversationCount,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
}

export function useMessageNotificationContext() {
    const { conversationCount, setUpdateConversationCount } = useContext(NotificationContext);
    return { conversationCount, setUpdateConversationCount };
}
