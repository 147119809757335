// export { PricingPage as default } from './PricingPage';

import { lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

const PricingPage = lazy(() => import('./PricingPage/index'));
const PaymentSuccess = lazy(() => import('./checkout/PaymentSuccess'));
const PaymentCancel = lazy(() => import('./checkout/PaymentCancel'));

export default function Billing() {
    return (
        <Routes>
            <Route path="/*" element={<Outlet />}>
                <Route path="price" element={<PricingPage />} />
                <Route path="success" element={<PaymentSuccess />} />
                <Route path="cancel" element={<PaymentCancel />} />
            </Route>
        </Routes>
    );
}
