/**
 * Renders the preloader
 */
const Loader = ({ message }: { message?: string }) => {
    return (
        <div className="preloader" id="preloader">
            <div className="status" id="status" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {message && <p style={{ width: 400, fontSize: 16, fontWeight: 400, textAlign: 'center' }}>{message}</p>}
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
