import { Collection, GetCollectionResponse } from '@/model/Collection';

export const getCollectionsMapper = (res: GetCollectionResponse): Collection[] => {
    return res.map((item) => {
        return {
            ...item,
            collectionId: item.id,
        };
    });
};
