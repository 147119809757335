import { Message } from '@/model/Message.ts';
import { Conversation } from '@/model/chat/Conversation.ts';
import classnames from 'classnames';
import { SenderType } from '@/model/SenderType.ts';
import { useMessage } from '@/pages/inbox/hooks/useMessage.ts';

type UserMessageProps = {
    message: Message;
    conversation: Conversation;
};

export const UserMessage = ({ message }: UserMessageProps) => {
    const { getMessageSenderName } = useMessage(message);

    return (
        <li className={classnames('clearfix', { odd: message.senderType !== SenderType.Customer })}>
            <div className="conversation-text">
                <div className="ctext-wrap">
                    <i>{getMessageSenderName()}</i>
                    <p>{message.messageBody}</p>
                </div>
            </div>
        </li>
    );
};
[];
