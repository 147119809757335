import { ClientFactory } from '@/client/ClientFactory.ts';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppContext } from '@/context/App.context';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Conversation } from '@/model/chat/Conversation';

type State = [Conversation | undefined, (conversation: Conversation) => void];

export default function useConversationDetail(): State {
    const inboxClient = ClientFactory.createInboxClient();
    const { serviceIds } = useAppContext();

    const [searchParams] = useSearchParams();
    const conversationId = searchParams.get('conversationId') as string;
    const [conversation, setConversation] = useState<Conversation>();

    const { data } = useQuery({
        queryKey: ['conversation', conversationId],
        queryFn: () => inboxClient.getConversation(serviceIds.serviceId, conversationId),
        enabled: conversationId !== null,
    });

    useEffect(() => {
        if (data) {
            setConversation(data);
        }
    }, [data]);

    const { mutate } = useMutation({
        mutationFn: async () => inboxClient.updateConversation(conversation as Conversation),
        onSuccess: () => {},
        onError: () => {},
    });

    const updateConversation = useCallback(
        (conversation: Conversation) => {
            setConversation(conversation);
            mutate();
        },
        [mutate],
    );

    return [conversation, updateConversation];
}
