import { Amplify } from 'aws-amplify';
import { ApplicationConfig } from '../../config/applicationConfig.ts';

export class AmplifyInitializer {
    public static initializeAmplifyWithBrowser() {
        this.initializeAmplify();
    }

    public static initializeAmplify() {
        Amplify.configure(ApplicationConfig.AWSConfigs.Auth);
    }
}
