import * as i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-localstorage-backend';

import common from './locales/en/common.json';
import home from './locales/en/home.json';
import register from './locales/en/register.json';

export const Languages = {
    EN: 'en',
};

const enTranslation = {
    ...common,
    ...home,
    ...register
}

export const isValidLanguage = (lang: string) => Object.values(Languages).includes(lang);

i18n.use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
        lng: Languages.EN,
        fallbackLng: ['en'],
        resources: {
            [Languages.EN]: { translation: enTranslation },
        },
    });

export default i18n;
