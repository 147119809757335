import { Dispatch, SetStateAction } from 'react';
import { URLParamReader } from '@/client/browser/URLParamReader.ts';
import { ClientFactory } from '@/client/ClientFactory.ts';
import { ServiceToRegister } from '@/model/ServiceToRegister';

export function useSetUp(serviceToRegister: Partial<ServiceToRegister>, setServiceToRegister: Dispatch<SetStateAction<Partial<ServiceToRegister>>>) {
    const serviceClient = ClientFactory.createGaramServiceClient();

    function setShoppingMallHomePageURL(url: string) {
        setServiceToRegister((prev) => ({
            ...prev,
            siteDomain: url,
        }));
    }

    async function autoSetUpService(): Promise<boolean> {
        if (!serviceToRegister.siteDomain && !URLParamReader.getShoppingMallDomainFromParam()) {
            return false;
        }

        if (!serviceToRegister.siteDomain) {
            serviceToRegister.siteDomain = URLParamReader.getShoppingMallDomainFromParam()!;
            setShoppingMallHomePageURL(URLParamReader.getShoppingMallDomainFromParam()!);
        }

        const scannedService = await serviceClient.scanHomePage(serviceToRegister.siteDomain!);

        if (!scannedService) {
            return false;
        }

        setServiceToRegister((prev) => ({
            ...prev,
            ...scannedService,
        }));

        return true;
    }

    function isSetUpComplete() {
        return serviceToRegister.siteDomain !== undefined && serviceToRegister.serviceName !== undefined && serviceToRegister.serviceDescription !== undefined;
    }

    return {
        autoSetUpService,
        isSetUpComplete,
    };
}
