import './SetUpService.css';
import { Dispatch, SetStateAction, useEffect } from 'react';
import ShoppingMallDomainInput from '@/pages/register/setup/ShoppingMallDomainInput.tsx';
import { useSetUp } from '@/pages/register/setup/useSetUp.ts';
import { useQuery } from '@tanstack/react-query';
import { ServiceToRegister } from '@/model/ServiceToRegister';
import { Loader } from '@/components';
import useMessageRotator from '@/hooks/useLoaderMessageList';

interface SetUpServiceProps {
    serviceToRegister: Partial<ServiceToRegister>;
    setServiceToRegister: Dispatch<SetStateAction<Partial<ServiceToRegister>>>;
}

export default function SetUpService({ serviceToRegister, setServiceToRegister }: SetUpServiceProps) {
    const { autoSetUpService } = useSetUp(serviceToRegister, setServiceToRegister);

    const { isLoading, refetch } = useQuery({
        queryKey: ['autoSetUpService', serviceToRegister.siteDomain],
        queryFn: autoSetUpService,
    });

    // TODO 번역
    const messageList = ['입력한 사이트의 정보를 찾고있어요. 🔍', '당신의 사이트 정보를 안전하게 확인했어요. 😎', '조금만 더 기다려 주세요. 😁'];
    const currentMessage = useMessageRotator(messageList, 3000);

    useEffect(() => {
        if (serviceToRegister.siteDomain) {
            refetch();
        }
    }, [serviceToRegister.siteDomain]);

    if (!isLoading && !serviceToRegister.siteDomain) {
        return <ShoppingMallDomainInput serviceToRegister={serviceToRegister} setServiceToRegister={setServiceToRegister} />;
    }

    return <Loader message={currentMessage} />;
}
