import { ReactNode } from 'react';
import {Container} from 'react-bootstrap';
import { Helmet } from 'react-helmet';

type PageTitleProps = {
    title: string;
    subNames?: {
        name: string;
        path?: string;
    }[];
    children?: ReactNode;
};

const PageBreadcrumb = ({ subNames = [], title, children }: PageTitleProps) => {
    return (
        <>
            <Helmet>
                <title>{title} | Garam</title>
            </Helmet>
            <Container className='mb-3'/>
        </>
    );
};

export default PageBreadcrumb;
