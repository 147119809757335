import { lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

const Playground = lazy(() => import('./Playground'));
const Configuration = lazy(() => import('./Configuration'));
const DataSource = lazy(() => import('./DataSource'));

export default function Chatbot() {
    return (
        <Routes>
            <Route path="/*" element={<Outlet />}>
                <Route path="dataSource/*" element={<DataSource />} />
                <Route path="configuration" element={<Configuration />} />
                <Route path="playground" element={<Playground />} />
            </Route>
        </Routes>
    );
}
