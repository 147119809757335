export enum MessageOwnerType {
    Application = 'Application',
    Customer = 'Customer',
}

export interface ChatMessage {
    id: string;
    type: MessageOwnerType;
    message: string;
}

export type GetAutocompleteSuggestionsRequest = {
    partialMessage: string;
    chatHistory: ChatMessage[];
    numChoices: number;
};

export type GetAutocompleteSuggestionsReseponse = {
    suggestions: string[];
};
