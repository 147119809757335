import { ReactNode } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BGCircles } from '@/components';
import Logo from '@/assets/custom/Transparent_Logo.svg';

type WrapperProps = {
    children?: ReactNode;
    bottomLinks?: ReactNode;
};

export default function Wrapper({ bottomLinks, children }: WrapperProps) {
    return (
        <>
            <BGCircles />
            <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5} xxl={4}>
                            <Card>
                                <Card.Header className="pt-4 pb-4 text-center bg-primary">
                                    <span>
                                        <img src={Logo} alt="" height="60" />
                                        <h4 style={{ color: 'white', display: 'inline' }}>Garam</h4>
                                    </span>
                                </Card.Header>
                                <Card.Body className="p-4">{children}</Card.Body>
                            </Card>
                            {bottomLinks}
                        </Col>
                    </Row>
                </Container>
            </div>
            <footer className="footer footer-alt">
                2024 - {new Date().getFullYear()} © Garam -
                <Link to="https://www.google.com/" target="_blank">
                    Garam.com
                </Link>
            </footer>
        </>
    );
}
