import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { ReactNode, createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NotificationContext = createContext<any>({});

type ToastrProps = {
    show: boolean;
    title?: string;
    message: string;
    variant?: string;
    delay?: number;
    onClose?: () => void;
    linkTo?: {
        to: string;
        message: string;
    };
};

type TypeVariant = {
    type: 'error' | 'info' | 'success' | 'default';
};

function Toastr({ show, title, message, onClose, variant = 'light', delay = 50000, linkTo }: ToastrProps) {
    const navigate = useNavigate();

    return (
        <ToastContainer className="p-3" position="top-end">
            <Toast bg={variant.toLowerCase()} delay={delay} show={show} onClose={onClose} autohide>
                {title && (
                    <Toast.Header className={`text-${variant}`}>
                        <strong className="me-auto">{title}</strong>
                    </Toast.Header>
                )}
                <Toast.Body className={['dark', 'danger'].includes(variant) ? 'text-white' : ''}>{message}</Toast.Body>
                {linkTo && (
                    <div
                        style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', padding: '0px 10px', cursor: 'pointer' }}
                        onClick={() => {
                            navigate(linkTo.to);
                        }}
                    >
                        {`${linkTo.message}`}
                        <i className="ri-arrow-right-circle-line font-22"></i>
                    </div>
                )}
            </Toast>
        </ToastContainer>
    );
}

export function useNotificationContext() {
    const context = useContext(NotificationContext);
    if (context === undefined) {
        throw new Error('useNotificationContext must be used within an NotificationProvider');
    }
    return context;
}

const notificationTypes = {
    error: 'danger',
    info: 'info',
    success: 'success',
    default: 'light',
};

export function NotificationProvider({ children }: { children: ReactNode }) {
    const [config, setConfig] = useState<ToastrProps>({
        show: false,
        message: '',
        title: '',
        linkTo: {
            to: '',
            message: '',
        },
    });

    const hideNotification = () => {
        setConfig({
            show: false,
            message: '',
            title: '',
            linkTo: {
                to: '',
                message: '',
            },
        });
    };
    const showNotification = ({ title, message, type, linkTo }: TypeVariant & ToastrProps) => {
        setConfig({
            show: true,
            title,
            message,
            linkTo,
            variant: notificationTypes[type] ?? 'light',
            onClose: hideNotification,
        });
    };

    return (
        <NotificationContext.Provider value={{ showNotification }}>
            <Toastr {...config} />
            {children}
        </NotificationContext.Provider>
    );
}
