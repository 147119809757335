import { Card } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { ConversationListItem } from './ConversationListItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ComponentLoader } from '@/components/ComponentLoader';
import { Conversation } from '@/model/chat/Conversation';
import { RefetchOptions } from '@tanstack/react-query';

interface Props {
    onClickConversation: (index: number) => void;
    selectedIndex: number;
    conversations: Conversation[];
    isLoadingConversation: boolean;
    refetch: (options?: RefetchOptions) => void;
    hasMore: boolean;
}

const ConversationList = ({ onClickConversation, selectedIndex, conversations, isLoadingConversation, hasMore, refetch }: Props) => {
    return (
        <Card style={{ height: '100%' }}>
            <Card.Body style={{ padding: '0px 2px 2px 2px' }}>
                <div className="tab-content">
                    <div className="tab-pane show active card-body" style={{ padding: '0px 0px' }}>
                        <ComponentLoader isLoading={isLoadingConversation} />
                        <SimpleBar style={{ maxHeight: '500', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <InfiniteScroll
                                dataLength={conversations.length}
                                style={{ height: 'calc(100vh - 150px)' }}
                                next={() => {
                                    if (hasMore) {
                                        refetch();
                                    }
                                }}
                                hasMore={hasMore}
                                loader={isLoadingConversation ? <h4>Loading...</h4> : null}
                            >
                                {conversations.map((conversation, index) => {
                                    return (
                                        <ConversationListItem
                                            key={conversation.id + index}
                                            conversation={conversation}
                                            isSelected={index === selectedIndex}
                                            onClick={() => {
                                                onClickConversation(index);
                                            }}
                                        />
                                    );
                                })}
                            </InfiniteScroll>
                        </SimpleBar>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}>
                    <p>{`Conversation Counts: ${conversations.length}`}</p>
                    {hasMore && <p>More Conversation</p>}
                </div>
            </Card.Body>
        </Card>
    );
};

export default ConversationList;
