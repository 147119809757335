import classnames from 'classnames';
import { format } from 'date-fns';
import { Conversation } from '@/model/chat/Conversation';
import { ConversationStatus } from '@/pages/inbox/types.ts';

interface Props {
    conversation: Conversation;
    onClick: (conversation: Conversation) => void;
    isSelected: boolean;
}

export function ConversationListItem({ conversation, isSelected, onClick }: Props): JSX.Element {
    return (
        <div className="text-body" onClick={() => onClick(conversation)}>
            <div
                className={classnames('d-flex', 'align-items-start', 'mt-1', 'p-2', {
                    'bg-light': isSelected,
                })}
            >
                <div className="w-100 overflow-hidden">
                    <h5 className="mt-0 mb-0 font-14">
                        <span className="float-end text-muted font-12">{format(conversation.lastMessageAt, 'MM/dd HH:mm')}</span> {conversation.chatSummary.shortSummary + ' '}
                        {conversation.status === ConversationStatus.Open && (
                            <svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
                                <circle r="4.5" cx="5" cy="5" fill="red" />
                            </svg>
                        )}
                    </h5>
                    <p className="mt-1 mb-0 text-muted font-14">
                        <span className="text-truncate w-75">{conversation.lastMessage}</span>
                    </p>
                </div>
            </div>
        </div>
    );
}
