import {withAuthenticator, WithAuthenticatorProps} from '@aws-amplify/ui-react';
import React from 'react';
import {AuthHeader} from "@/components/Auth/AuthHeader.tsx";

export function withUserLogin<Props = {}>(component: React.ComponentType<Props & WithAuthenticatorProps>) {
    return withAuthenticator(component, {
        signUpAttributes: ['given_name'],
        components: {
            Header: AuthHeader,
        }
    });
}
