import { ConversationStatus } from '@/pages/inbox/types.ts';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Spinner } from '@/components';
import { useCallback } from 'react';

interface Props {
    isLoading: boolean;
    status: ConversationStatus;
    updateConversationStatus: (status: ConversationStatus) => void;
}

export function ConversationStatusComponent({ isLoading, status, updateConversationStatus }: Props) {
    const onClickStatus = useCallback(
        (status: ConversationStatus) => {
            updateConversationStatus(status);
        },
        [updateConversationStatus],
    );
    return (
        <DropdownButton variant="light" title={isLoading ? <Spinner className="spinner-border-sm me-1" tag="span" color="white" /> : status} defaultValue={status}>
            {Object.values(ConversationStatus).map((item, index) => (
                <Dropdown.Item key={item + index} onClick={() => onClickStatus(item)} disabled={item === status}>
                    {item}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
}
