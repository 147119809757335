import { AuthUser, getCurrentUser, fetchAuthSession, fetchUserAttributes, updateUserAttributes } from 'aws-amplify/auth';
import { User } from '@/model/User.ts';
import { FacadeUser } from '@/client/user/model/FacadeUser.ts';

export class UserInfoClient {
    constructor(private readonly apiURL: string) {}

    public async getCurrentUser(): Promise<User> {
        try {
            const authUser: AuthUser = await getCurrentUser();
            const userAttributes = await fetchUserAttributes();
            const facadeUser = await this.getUserFromFacade(authUser.userId);
            return {
                id: authUser.userId,
                email: authUser.signInDetails?.loginId!,
                givenName: userAttributes.given_name ?? '',
                profileImageUrl: facadeUser.profileImageUrl,
            };
        } catch (error) {
            console.error('Failed to get current user session might be not logged in', error);
            throw new Error();
        }
    }

    public async updateGivenName(givenName: string): Promise<void> {
        await updateUserAttributes({
            userAttributes: {
                given_name: givenName,
            },
        });
    }

    public async getAccessToken(): Promise<string | undefined> {
        const authSession = await fetchAuthSession();

        return authSession.tokens?.accessToken.toString();
    }

    public async uploadProfileImage(serviceId: string, user: User, file: File): Promise<User> {
        const accessToken = await this.getOrThrowToken();
        const formData = new FormData();
        formData.append('file', file);

        const uploadResult = await (
            await fetch(`${this.apiURL}/service/${serviceId}/users/${user.id}/profileImage`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                method: 'POST',
                body: formData,
            })
        ).json();

        user.profileImageUrl = uploadResult.profileImageUrl;

        return user;
    }

    private async getUserFromFacade(userId: string): Promise<FacadeUser> {
        return (await fetch(`${this.apiURL}/users/${userId}`)).json();
    }

    private async getOrThrowToken(): Promise<string> {
        const token = await this.getAccessToken();
        if (!token) {
            throw new Error('No token found');
        }

        return token;
    }

    public async isUserLoggedIn(): Promise<boolean> {
        try {
            await getCurrentUser();
            return true;
        } catch (e) {
            return false;
        }
    }
}
