import { Outlet, Route, Routes } from 'react-router-dom';
import ConversationsApp from '@/pages/inbox/ConversationsApp.tsx';
import React from 'react';
import ConversationsDetail from './ConversationsDetail';

function Inbox() {
    return (
        <Routes>
            <Route path="/*" element={<Outlet />}>
                <Route path="conversations/detail" element={<ConversationsDetail />} />
                <Route path="conversations/*" element={<ConversationsApp />} />
            </Route>
        </Routes>
    );
}

export default React.memo(Inbox);
