import { Card, Col, Container, Row } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { Form, AutoCompleteTextInput } from '@/components';
import { useConversationArea } from './hooks';
import { chatSchema } from './hooks/useConversationArea.ts';
import { Conversation } from '@/model/chat/Conversation.ts';
import { ComponentLoader } from '@/components/ComponentLoader.tsx';
import { cloneDeep } from 'lodash';
import { ConversationStatusComponent } from './component/ConversationStatus.component.tsx';
import { ConversationStatus } from './types.ts';
import { SwitchButton } from '@/components/SwitchButton/SwitchButton.tsx';
import { useEffect, useRef } from 'react';
import { UserMessage } from './component/UserMessage.tsx';
import SimpleBarCore from 'simplebar-core';

interface ConversationAreaProps {
    selectedConversation: Conversation;
    updateConversation: (conversation: Conversation) => void;
}

const ConversationArea = ({ selectedConversation, updateConversation }: ConversationAreaProps) => {
    const { messages, sendChatMessage, isLoadingMessages, getHelpText } = useConversationArea(selectedConversation!);
    const chatMessageListRef = useRef(null);

    const scrollToBottom = () => {
        if (chatMessageListRef.current) {
            const scrollableNode: HTMLElement = (chatMessageListRef.current as SimpleBarCore).getScrollElement() as HTMLElement;
            scrollableNode.scrollTop = scrollableNode.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <Card style={{ height: '100%' }}>
            <Card.Body className="position-relative p-0">
                <Container className="px-2 py-1 border-bottom d-flex justify-content-center align-items-center">
                    <h4 className="px-1">Enable Chatbot</h4>
                    <div className="px-1">
                        <SwitchButton
                            status={selectedConversation.enableLLM}
                            onChangeStatus={() => {
                                const updatedConversation = cloneDeep(selectedConversation);
                                updatedConversation.enableLLM = !updatedConversation.enableLLM;
                                updateConversation(updatedConversation);
                            }}
                        />
                    </div>

                    <h4 className="px-2">Status </h4>
                    <ConversationStatusComponent
                        isLoading={false}
                        status={selectedConversation.status}
                        updateConversationStatus={(status: ConversationStatus) => {
                            const updatedConversation = cloneDeep(selectedConversation);
                            updatedConversation.status = status;
                            updateConversation(updatedConversation);
                        }}
                    />
                </Container>
                <SimpleBar style={{ height: 'calc(100vh - 280px)', position: 'relative', paddingTop: '10px' }} ref={chatMessageListRef}>
                    <ul className="conversation-list px-3">
                        <ComponentLoader isLoading={isLoadingMessages} />
                        {messages.map((message, index) => {
                            return <UserMessage key={index.toString()} message={message} conversation={selectedConversation!} />;
                        })}
                    </ul>
                </SimpleBar>

                <Row className="px-3 pb-3">
                    <Col>
                        <div className="mt-2 bg-light p-3 rounded">
                            <Form
                                name="chat-form"
                                id="chat-form"
                                onSubmit={async (value) => {
                                    await sendChatMessage(value);
                                }}
                                defaultValues={{ newMessage: '' }}
                                schema={chatSchema}
                            >
                                <Row>
                                    <div className="col mb-2 mb-sm-0">
                                        <AutoCompleteTextInput
                                            id="chat-textarea"
                                            type="text"
                                            name="newMessage"
                                            className="border-0"
                                            placeholder={getHelpText()}
                                            disabled={selectedConversation?.enableLLM}
                                            sendChatMessage={sendChatMessage}
                                            helpText={getHelpText()}
                                            messages={messages}
                                        />
                                    </div>
                                    <div className="col-sm-auto">
                                        <div className="btn-group">
                                            <button type="submit" className="btn btn-success chat-send btn-block">
                                                <i className="uil uil-message"></i>
                                            </button>
                                        </div>
                                    </div>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ConversationArea;
