import { Route, Routes as ReactRoutes, useNavigate } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import ErrorPages from '@/pages/error';
import ErrorPageNotFound from '@/pages/error/PageNotFound';
import Register from '@/pages/register';
import { useAppContext } from '@/context/App.context.tsx';
import { useEffect } from 'react';

export default function AppRoutes() {
    const { isRegister } = useAppContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isRegister) {
            navigate(`/register${location.search}`);
        }
    }, [isRegister]);

    return (
        <ReactRoutes>
            <Route path="/register" element={<Register />} />
            <Route path="/*" element={<ProtectedRoutes />} />
            <Route path="/error/*" element={<ErrorPages />} />
            <Route path="*" element={<ErrorPageNotFound />} />
        </ReactRoutes>
    );
}
