import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { ClientFactory } from '@/client/ClientFactory.ts';
import { useQuery } from '@tanstack/react-query';
import { Message } from '@/model/Message.ts';
import { v4 as uuidv4 } from 'uuid';
import { SenderType } from '@/model/SenderType.ts';
import { Conversation } from '@/model/chat/Conversation.ts';

/*
 * form validation schema
 */
export const chatSchema = yup.object().shape({
    newMessage: yup.string().required('Please enter your messsage'),
});

export type ChatFormFields = yup.InferType<typeof chatSchema>;

export default function useConversationArea(selectedConversation: Conversation) {
    const [messages, setMessages] = useState<Message[]>([]);
    const inboxClient = ClientFactory.createInboxClient();

    const { data, isLoading } = useQuery({
        queryKey: ['messages', selectedConversation.id],
        queryFn: async () => await inboxClient.getMessages(selectedConversation.id),
    });

    useEffect(() => {
        if (data) {
            setMessages(data);
        }

        inboxClient.listenConversation(selectedConversation.id, (message) => {
            setMessages((prevMessages) => {
                if (prevMessages[prevMessages.length - 1].id !== message.id) {
                    return [...prevMessages, message];
                }

                return prevMessages;
            });
        });
    }, [data]);

    /**
     * sends the chat message
     */
    const sendChatMessage = async (value: ChatFormFields) => {
        const newMessage: Message = {
            id: uuidv4(),
            senderType: SenderType.HumanAgent,
            messageBody: value.newMessage,
        };

        await inboxClient.sendMessage(selectedConversation.id, newMessage);

        const newUserMessages = [...messages];
        newUserMessages.push(newMessage);
        setMessages(newUserMessages);
    };

    const getHelpText = () => {
        if (selectedConversation!.enableLLM) {
            return 'To send message, Turn Off ChatBot first.';
        }
        return 'Enter your text';
    };

    return {
        messages: messages,
        isLoadingMessages: isLoading,
        sendChatMessage,
        getHelpText,
    };
}
