import { Col, Row } from 'react-bootstrap';
import { PageBreadcrumb } from '@/components';
import { NumberStatusCard } from '@/pages/home/cards/NumberStatusCard';
import { useTranslation } from 'react-i18next';
import { useMessageNotificationContext } from '@/context/NotificationContext';

const Home = (): JSX.Element => {
    const { conversationCount } = useMessageNotificationContext();
    const { t } = useTranslation();

    return (
        <>
            <PageBreadcrumb title={t('home')} />
            <Row className="mt-4">
                <h1 className="mb-4">{t('Welcome')}</h1>
                <h3 className="mb-4">{t('Insights')}</h3>
                <Col xl={4}>
                    <NumberStatusCard numberValue={conversationCount.open + conversationCount.closed} header={t('Total conversations')} />
                </Col>

                <Col xl={4}>
                    <NumberStatusCard numberValue={conversationCount.open} header={t('Total opened conversations')} />
                </Col>
            </Row>
        </>
    );
};

export { Home };
